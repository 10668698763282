<template>
    <span class="tag"
        :class="{
            'is-danger': postiveIsBad && movement === 'positive',
            // 'is-success': successIsNormal || dangerIsInverted && difference > 0,
            'is-success': !postiveIsBad && movement === 'positive',
            'is-info': movement === 'neutral'
        }">
            <span v-if="difference > 0 && successIsNormal">+</span>
            <span>{{ difference + '%' }}</span>
        </span>
</template>
<script>
export default {

    props: {
        current: Number,
        last: [Number, String],
        invert: Boolean,
        postiveIsBad: Boolean,
    },

    computed: {
        movement() {
            if(Number(this.last) === 0 && this.current === 0) return 'neutral'
            if(this.current > Number(this.last)) return 'positive'
            if(this.current < Number(this.last) && this.current > 0) return 'negative'
            return 'neutral'
        },
        difference() {
            if(Number(this.last) === 0 && this.current > 0) return Number('100')

            if(this.current > 0) {
                return Number((100 - ((this.current / Number(this.last)) * 100)).toFixed(2))
            }

            return '--'
        },
        successIsNormal() {
            return this.movement === 1 && this.invert === false
        },
        successIsInverted() {
            return this.movement === 1 && this.invert === true
        },
        dangerIsNormal() {
            return this.movement === -1 && this.invert === false
        },
        dangerIsInverted() {
            return this.movement === -1 && this.invert === true
        },
    }

}
</script>